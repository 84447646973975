import { gsap } from 'gsap';

export default class Videos {
  static init() {
    if (document.querySelector('.o-vid_launch') == null) return;
    this.videos = document.querySelectorAll('.o-vid_launch');
    for (let i = this.videos.length - 1; i >= 0; i--) { this.initVideos(this.videos[i].parentNode) }
  }

  static initVideos(video) {
    video.querySelector('.o-vid_launch').addEventListener('click', () => this.playVideo(video));
  }

  static playVideo(video) {
    gsap.to(video.querySelector('.o-vid_launch'), {opacity:0, duration:0.6, onComplete:()=>video.querySelector('.o-vid_launch').style.display = "none"})
    video.querySelector('video').play();
  }
}
