import { gsap } from "gsap";

export default class CookiesBanner {
  static init() {
    localStorage.getItem('cookiesNewheat') == null ? this.showBanner() : this.hideBanner();
  }

  static showBanner() {
    document.querySelector('.cookies').style.pointerEvents = "all";
    gsap.to('.cookies', {opacity:1, duration: 0.2, ease:'none', delay:1});
    document.querySelector('.cookies-close').addEventListener('click', () => this.hideBanner());
  }

  static hideBanner() {
    localStorage.setItem('cookiesNewheat', 'ok');
    gsap.to('.cookies', {opacity:0, duration: 0.2, ease:'none', onComplete:() => this.removeBanner()});
  }

  static removeBanner() {
    document.querySelector('.cookies').parentNode.removeChild(document.querySelector('.cookies'));
  }
}
