import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Headings {
  static init() {
    if (document.querySelector('.c-heading_bg')) this.parallaxCover();
    this.reveal();
  }

  static parallaxCover() {
    gsap.to('.c-heading_bg img, .c-heading-content', {
      scrollTrigger: {
        trigger: '.c-heading_bg',
        start: "top top",
        end: "bottom top",
        scrub: true
      },
      y: '150px',
      ease:"none"
    });
  }

  static reveal() {
    if (document.querySelector('.c-heading_title')) gsap.fromTo('.c-heading_title', { opacity:0, y: '2.5em'}, { opacity: 1, y:0, duration:0.9, ease:'power4.out' })
    if (document.querySelector('.c-heading_desc')) gsap.fromTo('.c-heading_desc', { opacity:0, y: '2.5em'}, { opacity: 1, y:0, duration:0.9, ease:'power4.out', delay: 0.15 })
  }
}
