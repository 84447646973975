import { ready } from './js/utils/utils';
import TouchDevice from './js/utils/touch-device';
import Sliders from './js/utils/sliders.js';
import Videos from './js/utils/videos.js';
import DevTools from './js/utils/dev-tools.js';
import CookiesBanner from './js/utils/cookies-banner.js';
import Header from './js/header';
import Headings from './js/headings';
import PageSolutions from './js/page-solutions';
import FilterRealisations from './js/filter-realisations';
import Forms from './js/forms';

ready()
  .then(() => {
    TouchDevice.init();
    // DevTools.init();
    CookiesBanner.init();
    Header.init();
    Headings.init();
    Sliders.init();
    Videos.init();
    PageSolutions.init();
    FilterRealisations.init();
    Forms.init();
  });
