import { gsap } from 'gsap';
import { indexInParent } from './utils/utils';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class PageSolutions {
  static init() {
    // if (!document.body.classList.contains('page-template-page-solutions')) return;
    if (document.querySelector('.o-process')) this.process();
    if (document.querySelector('.s-sol-list_img') && window.innerWidth > 600) this.parallaxBigImage();
  }

  static process() {
    const list = document.querySelector('.o-process_list');
    const lines = document.querySelectorAll('.o-process_progress');

    Array.prototype.forEach.call(lines, (line) => {
      gsap.to(line, {
        scrollTrigger: {
          trigger: line.parentNode,
          start: "top 495",
          end: "bottom 505",
          scrub: true,
          onUpdate: self => {
            if (indexInParent(self.trigger) == 0) {
              if (self.progress > 0 && !list.querySelector('.o-process_item:nth-of-type(1)').classList.contains('is-active')) {
                list.querySelector('.o-process_item:nth-of-type(1)').classList.add('is-active');
              } else if (self.progress == 0 && list.querySelector('.o-process_item:nth-of-type(1)').classList.contains('is-active')) {
                list.querySelector('.o-process_item:nth-of-type(1)').classList.remove('is-active');
              }
            }
            if (self.progress > 0.99) {
              if (!list.querySelector('.o-process_item:nth-of-type('+ parseInt(indexInParent(self.trigger) + 2) +')').classList.contains('is-active')) list.querySelector('.o-process_item:nth-of-type('+ parseInt(indexInParent(self.trigger) + 2) +')').classList.add('is-active');
            } else {
              if (list.querySelector('.o-process_item:nth-of-type('+ parseInt(indexInParent(self.trigger) + 2) +')').classList.contains('is-active')) list.querySelector('.o-process_item:nth-of-type('+ parseInt(indexInParent(self.trigger) + 2) +')').classList.remove('is-active');
            }
          }
        },
        scaleY: 1,
        ease:"none"
      });
    });
  }

  static parallaxBigImage() {
    gsap.to('.s-sol-list_img', {
      scrollTrigger: {
        trigger: '.s-sol-list',
        start: "top bottom",
        end: "bottom top",
        scrub: true
      },
      y: '-40%',
      ease:"none"
    });
  }
}
