import { gsap } from 'gsap';

export default class FilterRealisations {
  static init() {
    this.filters = document.querySelectorAll('.o-realisations-list_filter');
    Array.prototype.forEach.call(this.filters, (el) => {
      new FilterRealisations(el.parentNode);
    });
  }

  constructor(container) {
    const filters = container.querySelectorAll('.o-realisations-list_term');
    Array.prototype.forEach.call(filters, (item) => {
      item.addEventListener('click', () => FilterRealisations.filter(item));
    });
  }

  static filter(item) {
    const category = item.getAttribute('category');
    const container = item.parentNode.parentNode;
    container.querySelector('.o-realisations-list_term.is-active').classList.remove('is-active');
    container.querySelector('.o-realisations-list_term[category="'+category+'"').classList.add('is-active');

    gsap.to(container.querySelector('.o-realisations-list_inner'), {opacity:0, duration:0.2, ease:'none', onComplete: () => {
      FilterRealisations.switchItems(category, container);
      gsap.to(container.querySelector('.o-realisations-list_inner'), {opacity:1, duration:0.2, ease:'none'});
    }})
  }

  static switchItems(category, container) {
    if (category == "all") {
      Array.prototype.forEach.call(container.querySelectorAll('.o-realisations-list_item'), (el) => el.style.display = "block")
    } else {
      Array.prototype.forEach.call(container.querySelectorAll('.o-realisations-list_item'), (el) => {
        el.getAttribute('category') == category ? el.style.display = "block" : el.style.display = "none";
      })
    }
  }
}
