import { tns } from 'tiny-slider/src/tiny-slider';

export default class Sliders {
  static init() {
    if (document.querySelector('.o-slider') == null) return;
    this.sliders = document.querySelectorAll('.o-slider');
    for (let i = this.sliders.length - 1; i >= 0; i--) { this.initSlider(this.sliders[i]) }
  }

  static initSlider(slider) {
    tns({
      container: slider.querySelector('.o-slider_slides'),
      autoHeight: true,
      items: 1,
      loop: false,
      autoplay: false,
      mouseDrag: true,
      nav: false,
      controls: true,
      controlsContainer: slider.querySelector('.o-slider_controls')
    });
  }
}
