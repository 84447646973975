import { gsap } from 'gsap';

export default class Header {
  static init() {
    this.header = document.querySelector('.c-header');
    this.menuButton = this.header.querySelector('.menu-btn');
    this.isMenuVisible = false;
    this.menuButton.addEventListener('click', () => this.toggleMenu());
    this.scrolling();
    if (!document.querySelector('.img-heading')) this.header.classList.add('solid');
    window.addEventListener("scroll", () => this.scrolling(), false);

    this.addBackground();

    if (this.header.querySelector('.sub-menu')) Array.prototype.forEach.call(this.header.querySelectorAll('.sub-menu'), (submenu) => this.initSubMenus(submenu));
  }

  static scrolling() {
    // const st = window.pageYOffset || document.documentElement.scrollTop;
    // st > this.lastScrollTop ? this.logoSymbol() : this.logoFull();

    if (document.querySelector('.img-heading')) {
      document.querySelector('.img-heading').getBoundingClientRect().bottom < this.header.clientHeight ? this.headerTransparent() : this.headerSolid();
    }

    // this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }

  static headerTransparent() {
    if (!this.header.classList.contains('solid')) this.header.classList.add('solid');
  }

  static headerSolid() {
    if (this.header.classList.contains('solid')) this.header.classList.remove('solid');
  }

  static addBackground() {
    let headerBg = document.createElement("div");
    headerBg.classList.add('header-bg');
    this.header.appendChild(headerBg);
  }

  static initSubMenus(submenu) {
    if (window.innerWidth < 1121) return false;
    submenu.parentNode.addEventListener('mouseenter', () => {
      submenu.parentNode.querySelector('a').classList.add('is-open');
      gsap.set(this.header.querySelector('.header-bg'), { y: submenu.clientHeight+'px' })
    })

    submenu.parentNode.addEventListener('mouseleave', () => {
      submenu.parentNode.querySelector('a').classList.remove('is-open');
      gsap.set(this.header.querySelector('.header-bg'), { clearProps:"transform" })
    })
  }

  static toggleMenu() {
    !this.isMenuVisible ? this.showMenu() : this.hideMenu();
  }

  static showMenu() {
    this.isMenuVisible = true;
    this.header.classList.add('is-open');
  }

  static hideMenu() {
    this.isMenuVisible = false;
    this.header.classList.remove('is-open');
  }
}
