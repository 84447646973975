export default class TouchDevice {
  static init() {
    if (this.isTouchDevice()) {
      document.body.classList.add('touch-device');
    }
  }

  static isTouchDevice() {
    if (this.isTouch) {
      return this.isTouch;
    }

    if (('ontouchstart' in window) || (window.DocumentTouch && document instanceof window.DocumentTouch)) {
      this.isTouch = true;
      return this.isTouch;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = query => window.matchMedia(query).matches;
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    this.isTouch = mq(query);
    return this.isTouch;
  }
}
